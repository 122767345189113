import styles from "./PhoneVersion.module.css";

const PhoneVersion = () => {
  return (
    <div className={styles.phoneVersion}>
      <div className={styles.bgLine}>
        <div className={styles.line} />
        <div className={styles.line1} />
        <div className={styles.line2} />
        <div className={styles.line3} />
        <div className={styles.logo}>
          <img
            className={styles.thumbnailVrindavanFinalRemoIcon}
            alt=""
            src="/thumbnail-vrindavan-finalremovebgpreview-1@2x.png"
          />
          <img
            className={styles.thumbnailVrindavanFinalRemoIcon1}
            alt=""
            src="/thumbnail-vrindavan-finalremovebgpreview-2@2x.png"
          />
        </div>
      </div>
      <div className={styles.heroSlide}>
        <img className={styles.imageIcon} alt="" src="/image@2x.png" />
        <div className={styles.heroText}>
          <div className={styles.yourPremierEventContainer}>
            <span className={styles.yourPremierEventContainer1}>
              <p className={styles.yourPremier}>{`Your Premier `}</p>
              <p className={styles.yourPremier}>Event Destination.</p>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.subContent}>
        <div className={styles.escapeToTranquilityContainer}>
          <span className={styles.yourPremierEventContainer1}>
            <p className={styles.yourPremier}>{`Escape to Tranquility, `}</p>
            <p className={styles.yourPremier}>Celebrate with Elegance</p>
          </span>
        </div>
        <div className={styles.imageWrapper}>
          <img className={styles.imageIcon1} alt="" src="/image1@2x.png" />
        </div>
        <div className={styles.nestledInThe}>
          Nestled in the heart of Mysore, Vrindavan Retreat is your idyllic
          haven for unforgettable events and cherished memories. Our picturesque
          resort offers a perfect blend of natural beauty, modern amenities, and
          impeccable service, making it the ideal setting for your next event.
        </div>
        <div className={styles.button}>
          <div className={styles.button1}>
            <div className={styles.getFreeEstimate}>
              <a href="#Contact" className={styles.linkStylePlain}>Get Free Estimate</a>
              <img className={styles.vectorIcon} alt="" src="/vector.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerParent}>
        <div className={styles.header}>
          <div className={styles.ourVenue}>Our Venue</div>
          <div className={styles.aWorldOf}>
            A World of Choices, a Venue of Awe-inspiring Beauty
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.maskGroupParent}>
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="/mask-group@2x.png"
            />
            <div className={styles.naturalBeauty}>Natural Beauty</div>
            <div className={styles.ourSprawlingGrounds}>
              Our sprawling grounds are a canvas of natural beauty, with lush
              greenery and scenic vistas that create a serene atmosphere for
              your event.
            </div>
          </div>
          <div className={styles.maskGroupParent}>
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="/mask-group1@2x.png"
            />
            <div className={styles.naturalBeauty}>Versatile Spaces</div>
            <div className={styles.ourSprawlingGrounds}>
              {" "}
              We offer a variety of outdoor spaces to suit your needs, from open
              lawns for large gatherings to intimate garden areas for smaller
              affairs.
            </div>
          </div>
          <div className={styles.ourVenue1}>
            <div className={styles.imageWrapper}>
              <div className={styles.maskGroupContainer}>
                <img
                  className={styles.maskGroupIcon}
                  alt=""
                  src="/mask-group2@2x.png"
                />
                <div className={styles.naturalBeauty}>Modern Amenities</div>
                <div
                  className={styles.ourSprawlingGrounds}
                >{`We haven't forgotten about modern conveniences, our venue is equipped with state-of-the-art audiovisual equipment & facilities.`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerGroup}>
        <div className={styles.header}>
          <div className={styles.ourVenue}>Property Tour</div>
          <div className={styles.aWorldOf}>
            Discover the charm and elegance of Vrindavan Retreat
          </div>
        </div>
        <div>
        <iframe className={styles.heroSlide1} src="https://www.youtube.com/embed/pSOwVrD-228?playlist=pSOwVrD-228&autoplay=1&loop=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
        </div>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.header2}>
          <div className={styles.ourVenue}>Designed for you</div>
          <div className={styles.ourResortBoasts}>
            Our resort boasts lush gardens, breathtaking waterfront views, and a
            variety of event spaces to accommodate gatherings of all sizes, from
            intimate celebrations to grand galas.
          </div>
        </div>
        < div className={styles.heroSlide2}>
        <img
          className={styles.maskGroupIcon4}
          alt=""
          src="/mask-group13@2xcopy.png"
        />
          <div className={styles.heroText1}>
            <div className={styles.weddings}>Weddings</div>
          </div>
          <div className={styles.heroText2}>
            <div
              className={styles.weddingsSangeetsHaldhi}
            >{`Weddings, Sangeets, Haldhi `}</div>
          </div>
        </div>
      </div>
      <div className={styles.amenities}>
        <div className={styles.frameGroup}>
          <div className={styles.frameWrapper}>
            <div className={styles.frameDiv}>
              <img
                className={styles.maskGroupIcon3}
                alt=""
                src="/mask-group3@2x.png"
              />
              <div className={styles.naturalBeauty}>Modern Kitchen</div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.maskGroupParent1}>
              <img
                className={styles.maskGroupIcon3}
                alt=""
                src="/mask-group4@2x.png"
              />
              <div className={styles.naturalBeauty}>{`2 Rooms `}</div>
            </div>
          </div>
          <div className={styles.frameWrapper1}>
            <div className={styles.maskGroupParent2}>
              <img
                className={styles.maskGroupIcon3}
                alt=""
                src="/mask-group5@2x.png"
              />
              <div className={styles.naturalBeauty}>Kids Play Area</div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.maskGroupParent3}>
              <img
                className={styles.maskGroupIcon3}
                alt=""
                src="/mask-group6@2x.png"
              />
              <div className={styles.naturalBeauty}>{`2 Swimming pools `}</div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.maskGroupParent4}>
              <img
                className={styles.maskGroupIcon3}
                alt=""
                src="/mask-group7@2x.png"
              />
              <div className={styles.naturalBeauty}>Cricket play Area</div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.maskGroupParent5}>
              <img
                className={styles.maskGroupIcon3}
                alt=""
                src="/mask-group8@2x.png"
              />
              <div className={styles.naturalBeauty}>Badminton Play Area</div>
            </div>
          </div>
        </div>
        <div className={styles.header3}>
          <div className={styles.ourVenue}>Our Amenities</div>
          <div className={styles.aWorldOf1}>
            A World of Choices, a Venue of Awe-inspiring Beauty
          </div>
        </div>
      </div>
      <div className={styles.headerParent1}>
        <div className={styles.header2}>
          <div className={styles.ourVenue}>Our Events</div>
          <div className={styles.ourResortBoasts}>
            Our venue provides a stunning backdrop that requires minimal
            decoration.
          </div>
        </div>
        <div className={styles.heroSlide2}>
        <img width="100%" height="100%"
          alt=""
          src="/IMG-20230935.jpg"
        />
        </div>
      </div>
      <div className={styles.headerParent2}>
        <div className={styles.header2}>
          <div className={styles.celebrateLifeSurrounded} id="Contact">
            Celebrate Life, Surrounded by Nature's Finest.
          </div>
          <div className={styles.ourResortBoasts}>
            Our venue provides a stunning backdrop that requires minimal
            decoration.
          </div>
        </div>
        <div className={styles.heroSlide4}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15590.23168316469!2d76.7053346!3d12.3455144!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf71031439f889%3A0x8884ab0043c69573!2sVrindavan%20Retreat%20-SS%20Farm!5e0!3m2!1sen!2sin!4v1696612164126!5m2!1sen!2sin" width="600" height="450" frameBorder="0" loading="lazy"></iframe>
        </div>
        <div className={styles.frameParent1}>
          <div className={styles.groupParent}>
            <div className={styles.nameParent}>
              <div className={styles.name}>Name</div>
              <div className={styles.groupChild} />
            </div>
            <div className={styles.phoneNumberParent}>
              <div className={styles.phoneNumber}>Phone Number</div>
              <div className={styles.groupItem} />
            </div>
            <div className={styles.eMailParent}>
              <div className={styles.name}>E-mail</div>
              <div className={styles.groupInner} />
            </div>
            <div className={styles.phoneNumberParent}>
              <div className={styles.message}>Message</div>
              <div className={styles.groupItem} />
            </div>
          </div>
          <div className={styles.button2}>
            <div className={styles.button1}>
              <div className={styles.getFreeEstimate}>
                <div className={styles.getFreeEstimate1}>Get Free Estimate</div>
                <img className={styles.vectorIcon} alt="" src="/vector.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactParent}>
        <div className={styles.contact}>
          <div className={styles.contact1}>Contact</div>
          <div className={styles.ssFarmMysuruKarnatakaContainer}>
            <span className={styles.yourPremierEventContainer1}>
              <p className={styles.yourPremier}>SS Farm, Mysuru,</p>
              <p className={styles.yourPremier}>Karnataka 570019</p>
              <p className={styles.yourPremier}>+91 63601 98255</p>
              <p className={styles.yourPremier}>hello@vrindavanretreat.com</p>
            </span>
          </div>
        </div>
        <div className={styles.interno}>
          <div className={styles.subContent1}>
            <div className={styles.logo1}>
              <div className={styles.vrindavanRetreat}>Vrindavan Retreat</div>
            </div>
            <div className={styles.whereMemoriesBecome}>
              Where Moments Become Memories: Your Perfect Event Awaits!
            </div>
            <div className={styles.copyrightVrindavan}>
              Copyright © Vrindavan Retreat
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneVersion;
