import styles from "./VrindavanRetreatDesign.module.css";

const VrindavanRetreatDesign = () => {
  return (
    <div className={styles.vrindavanRetreatDesign}>
      <img className={styles.bgLineIcon} alt="" src="/bg-line.svg" />
      <div className={styles.menuBar}>
        <div className={styles.logo}>
          <img
            className={styles.thumbnailVrindavanFinalRemoIcon}
            alt=""
            src="/thumbnail-vrindavan-finalremovebgpreview-1@2x.png"
          />
          <span><img
            className={styles.thumbnailVrindavanFinalRemoIcon1}
            alt=""
            src="/thumbnail-vrindavan-finalremovebgpreview-2@2x.png"
          /></span>
        </div>
        <div className={styles.menuBar1}>
          <a href="#" className={styles.linkStyle}>Home</a>
          <a href="#About" className={styles.linkStyle}>About</a>
          <a href="#Contact" className={styles.linkStyle}>{`Connect `}</a>
        </div>
      </div>
      <div className={styles.heroSlide}>
        <div className={styles.image}>
          <img className={styles.imageIcon} alt="" src="/image2@2x.png" />
        </div>
        <div className={styles.heroText}>
          <div className={styles.yourPremierEventContainer}>
            <span className={styles.yourPremierEventContainer1}>
              <p className={styles.yourPremier}>{`Your Premier `}</p>
              <p className={styles.yourPremier}>Event Destination.</p>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.aboutUs}>
        <div className={styles.subContentParent}>
          <div className={styles.subContent}>
            <div className={styles.escapeToTranquilityContainer}>
              <span className={styles.yourPremierEventContainer1}>
                <p
                  className={styles.yourPremier}
                >{`Escape to Tranquility, `}</p>
                <p className={styles.yourPremier}>Celebrate with Elegance</p>
              </span>
            </div>
            <div className={styles.nestledInThe}>
              Nestled in the heart of Mysore, Vrindavan Retreat is your idyllic
              haven for unforgettable events and cherished memories. Our
              picturesque resort offers a perfect blend of natural beauty,
              modern amenities, and impeccable service, making it the ideal
              setting for your next event.
            </div>
            <div className={styles.button}>
               <div className={styles.button1}>
                <div className={styles.getFreeEstimate}>
                  <div className={styles.getFreeEstimate1}>
                    <a href="#Contact" className={styles.linkStylePlain}>Get Free Estimate</a>
                  </div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <img className={styles.imageIcon1} alt="" src="/image3@2x.png" />
        </div>
      </div>
      <div className={styles.ourVenue}>
        <div className={styles.header}>
          <div className={styles.ourAmenities}>Our Venue</div>
          <div className={styles.aWorldOf}>
            A World of Choices, a Venue of Awe-inspiring Beauty
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.maskGroupParent}>
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="/mask-group9@2x.png"
            />
            <div className={styles.naturalBeauty}>Natural Beauty</div>
            <div className={styles.ourSprawlingGrounds}>
              Our sprawling grounds are a canvas of natural beauty, with lush
              greenery and scenic vistas that create a serene atmosphere for
              your event.
            </div>
          </div>
          <div className={styles.maskGroupParent}>
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="/mask-group10@2x.png"
            />
            <div className={styles.naturalBeauty}>Versatile Spaces</div>
            <div className={styles.ourSprawlingGrounds}>
              {" "}
              We offer a variety of outdoor spaces to suit your needs, from open
              lawns for large gatherings to intimate garden areas for smaller
              affairs.
            </div>
          </div>
          <div className={styles.maskGroupContainer}>
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="/mask-group11@2x.png"
            />
            <div className={styles.naturalBeauty}>Modern Amenities</div>
            <div
              className={styles.ourSprawlingGrounds}
            >{`We haven't forgotten about modern conveniences, our venue is equipped with state-of-the-art audiovisual equipment & facilities.`}</div>
          </div>
        </div>
      </div>
      <div className={styles.propertyTour}>
        <div className={styles.header}>
          <div className={styles.ourAmenities}>Property Tour</div>
          <div className={styles.aWorldOf}>
            Discover the charm and elegance of Vrindavan Retreat
          </div>
        </div>
        <div className={styles.frameDiv}>
          <iframe className={styles.maskGroupIcon3} src="https://www.youtube.com/embed/pSOwVrD-228?playlist=pSOwVrD-228&autoplay=1&loop=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
        </div>
      </div>
      <div className={styles.designedForYou}>
        <div className={styles.header}>
          <div className={styles.ourAmenities}>Designed for you</div>
          <div className={styles.aWorldOf}>
            Our resort boasts lush gardens, breathtaking waterfront views, and a
            variety of event spaces to accommodate gatherings of all sizes, from
            intimate celebrations to grand galas.
          </div>
        </div>
        <div className={styles.maskGroupParent1}>
          <img
            className={styles.maskGroupIcon4}
            alt=""
            src="/mask-group13@2x.png"
          />
          <div className={styles.weddingsParent}>
            <div className={styles.weddings}>Weddings</div>
            <div
              className={styles.weddingsSangeetsHaldhi}
            >{`Weddings, Sangeets, Haldhi `}</div>
          </div>
        </div>
      </div>
      <div className={styles.amenities}>
        <div className={styles.headerParent}>
          <div className={styles.header}>
            <div className={styles.ourAmenities} id="About">Our Amenities</div>
            <div className={styles.weddingsSangeetsHaldhi}>
              A World of Choices, a Venue of Awe-inspiring Beauty
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameWrapper}>
              <div className={styles.maskGroupParent2}>
                <img
                  className={styles.maskGroupIcon5}
                  alt=""
                  src="/mask-group14@2x.png"
                />
                <div className={styles.naturalBeauty}>Modern Kitchen</div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.maskGroupParent3}>
                <img
                  className={styles.maskGroupIcon5}
                  alt=""
                  src="/mask-group15@2x.png"
                />
                <div className={styles.naturalBeauty}>{`2 Rooms `}</div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.maskGroupParent4}>
                <img
                  className={styles.maskGroupIcon5}
                  alt=""
                  src="/mask-group16@2x.png"
                />
                <div className={styles.naturalBeauty}>Kids Play Area</div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.maskGroupParent5}>
                <img
                  className={styles.maskGroupIcon5}
                  alt=""
                  src="/mask-group17@2x.png"
                />
                <div
                  className={styles.naturalBeauty}
                >{`2 Swimming pools `}</div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.maskGroupParent6}>
                <img
                  className={styles.maskGroupIcon5}
                  alt=""
                  src="/mask-group18@2x.png"
                />
                <div className={styles.naturalBeauty}>Cricket play Area</div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.maskGroupParent7}>
                <img
                  className={styles.maskGroupIcon5}
                  alt=""
                  src="/mask-group19@2x.png"
                />
                <div className={styles.naturalBeauty}>Badminton Play Area</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.evets}>
        <div className={styles.heading}>
          <div className={styles.ourAmenities}>Our Events</div>
          <div className={styles.aWorldOf}>
            Our venue provides a stunning backdrop that requires minimal
            decoration.
          </div>
        </div>
        <div className={styles.rectangleParent}>
        <div className={styles.groupChild}>
          <img width="100%" height="100%"
            alt=""
            src="/IMG-20230947.jpg"
          />
        </div>
        <div className={styles.groupItem}>
          <img width="100%" height="100%"
            alt=""
            src="/IMG-20230921.jpg"
          />
          </div>
        <div className={styles.groupInner}>
          <img width="100%" height="100%"
            alt=""
            src="/IMG-20230935.jpg"
          />
        </div>
        <div className={styles.rectangleDiv} >
          <img width="100%" height="100%"
            alt=""
            src="/IMG-20230954.jpg"
          />
        </div>
        </div>
      </div>
      <div className={styles.contactUs}>
        <div className={styles.heading1}>
          <div className={styles.ourAmenities} id="Contact">
            Celebrate Life, Surrounded by Nature's Finest.
          </div>
          <div className={styles.wedLoveToContainer}>
            <span className={styles.yourPremierEventContainer1}>
              <p
                className={styles.yourPremier}
              >{`We'd love to hear from you and help you plan your `}</p>
              <p className={styles.yourPremier}>
                upcoming event at Vrindavan Retreat.
              </p>
            </span>
          </div>
        </div>
        <div className={styles.rectangleGroup}>
        <div className={styles.heroSlide4}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15590.23168316469!2d76.7053346!3d12.3455144!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf71031439f889%3A0x8884ab0043c69573!2sVrindavan%20Retreat%20-SS%20Farm!5e0!3m2!1sen!2sin!4v1696612164126!5m2!1sen!2sin" width="600" height="450" frameBorder="0" loading="lazy"></iframe>
        </div>
          <div className={styles.frameParent1}>
            <div className={styles.groupParent}>
              <div className={styles.nameParent}>
                <div className={styles.name}>Name</div>
                <div className={styles.lineDiv} />
              </div>
              <div className={styles.nameParent}>
                <div className={styles.phoneNumber}>Phone Number</div>
                <div className={styles.lineDiv} />
              </div>
              <div className={styles.nameParent}>
                <div className={styles.name}>E-mail</div>
                <div className={styles.lineDiv} />
              </div>
              <div className={styles.nameParent}>
                <div className={styles.message}>Message</div>
                <div className={styles.lineDiv} />
              </div>
            </div>
            <div className={styles.button2}>
              <div className={styles.getFreeEstimate}>
                <div className={styles.getFreeEstimate1}>Get Free Estimate</div>
                <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.interno}>
          <div className={styles.subContent1}>
            <div className={styles.logo1}>
              <div className={styles.vrindavanRetreat}>Vrindavan Retreat</div>
            </div>
            <div className={styles.whereMomentsBecome}>
              Where Moments Become Memories: Your Perfect Event Awaits!
            </div>
            <div className={styles.copyrightVrindavan}>
              Copyright © Vrindavan Retreat
            </div>
          </div>
        </div>
        <div className={styles.contact}>
          <div className={styles.contact1}>Contact</div>
          <div className={styles.ssFarmMysuruKarnatakaContainer}>
            <span className={styles.yourPremierEventContainer1}>
              <p className={styles.yourPremier}>SS Farm, Mysuru,</p>
              <p className={styles.yourPremier}>Karnataka 570019</p>
              <p className={styles.yourPremier}>+91 63601 98255</p>
              <p className={styles.yourPremier}>Hello@vrindavanretreat.com</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VrindavanRetreatDesign;
